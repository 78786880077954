.centro-win-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.centro-win-container .center {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.centro-win-container h1 {
  text-align: center;
}

.centro-win-container a {
  text-decoration: underline;
}

.centro-win-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}


.centro-win-container li {
  padding-left: 1rem;
  text-indent: -0.7rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.whats-next-list li:nth-child(1)::before {
  content: "⚡️ ";
}

.whats-next-list li:nth-child(2)::before {
  content: "☁️ ";
}

.quick-start-list li:nth-child(1)::before {
  content: "🐇 ";
}

.help-list li:nth-child(1)::before {
  content: "💼 ";
}

.help-list li:nth-child(2)::before {
  content: "📅 ";
}

.centro-win-container h3 {
  margin-bottom: 1rem;
}


.centro-win-container .message {
  margin: 2rem;
  background-color: rgba(200, 200, 200, 0.2);
  padding: 1rem;
  border-radius: 15px;
}

.centro-win-container button {
  margin: 0.5rem;
}

.centro-app-home {
  background-color: #ff6501;
  justify-content: center;
  font-weight: 900;
  font-size: 15px;
  color: #fff;
  min-width: 80px;
  cursor: pointer;
  text-decoration: none;
  padding: 14px;
  margin-left: 0.25rem;
  font-size: 18px;
  vertical-align: bottom;
  border-radius: 50px;
  white-space: nowrap;
  position: relative;
  line-height: 1.2rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .1);
  text-align: center;
  direction: ltr;
}

.centro-app-home .slds-icon {
  width: auto;
  height: auto;
}